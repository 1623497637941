<template>
  <div class="content">
    <div class="accountCon">
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            v-loading="loading"
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
          >
            <ElTableColumn label="录入时间" width="500" prop="created_at"  fixed="left"/>
            <ElTableColumn label="积分变动" prop="num" width="500"  fixed="left"/>
            <ElTableColumn label="积分来源" prop="message"  fixed="right"/>
          </el-table>
          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          />
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getPersonPoint } from "@/api/client";
import pagination from "@/components/Pagination";


class Page {
  total = 0;
  page = 1;
  limit = 10;
}
export default {
  components: {
    pagination
  },
  data() {
    return {
      page: new Page(),
      list: [],
      id:"",
      loading:false,
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      this.loading = true;
      let id  = this.id;
      getPersonPoint({ ...this.page,id:id}).then(
        res => {
          this.list = res.data.log;
          this.page.total = res.total;
          this.loading = false;
        }
      );
    },
  }
};
</script>

<style lang="scss" scoped>

</style>
